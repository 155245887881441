// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ablauf-js": () => import("./../src/pages/ablauf.js" /* webpackChunkName: "component---src-pages-ablauf-js" */),
  "component---src-pages-betreuungsangebote-js": () => import("./../src/pages/betreuungsangebote.js" /* webpackChunkName: "component---src-pages-betreuungsangebote-js" */),
  "component---src-pages-buchen-js": () => import("./../src/pages/buchen.js" /* webpackChunkName: "component---src-pages-buchen-js" */),
  "component---src-pages-flexi-js": () => import("./../src/pages/flexi.js" /* webpackChunkName: "component---src-pages-flexi-js" */),
  "component---src-pages-gallerie-js": () => import("./../src/pages/gallerie.js" /* webpackChunkName: "component---src-pages-gallerie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oeffnungszeiten-js": () => import("./../src/pages/oeffnungszeiten.js" /* webpackChunkName: "component---src-pages-oeffnungszeiten-js" */),
  "component---src-pages-paedagogik-js": () => import("./../src/pages/paedagogik.js" /* webpackChunkName: "component---src-pages-paedagogik-js" */),
  "component---src-pages-playground-js": () => import("./../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-standard-js": () => import("./../src/pages/standard.js" /* webpackChunkName: "component---src-pages-standard-js" */),
  "component---src-pages-tagesablauf-js": () => import("./../src/pages/tagesablauf.js" /* webpackChunkName: "component---src-pages-tagesablauf-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

